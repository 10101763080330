<template>
  <div>
    <header class="header">
      <div class="container">
        <div class="p-relative">
          <div v-if="windowWidth > 576" class="header__nav">
            <div class="container header__nav_block d-flex align-items-center justify-content-between">
              <div class="header__logo">
                <router-link :to="{name: 'home'}">
                  <img src="@/assets/images/header_logo.svg" alt="logo">
                </router-link>
              </div>
              <div class="header__links">
                <a href="tel:998555017400">55 501 74 00</a>
                <a href="https://xonsaroy.uz/uz" target="_blank"><img src="@/assets/images/web_logo.svg" alt=""></a>
                <a href="https://t.me/XonSaroy" target="_blank"><img src="@/assets/images/telegram_logo.svg"
                                                                     alt=""></a>
                <a href="https://www.instagram.com/xonsaroyuz/" target="_blank"><img
                  src="@/assets/images/instagram_logo.svg" alt=""></a>
              </div>
            </div>
          </div>
          <div v-else class="header__nav-mob container d-flex align-items-center justify-content-between">
            <div class="col-6 col-sm-4">
              <a href="#">
                <img src="@/assets/images/header_logo.svg" alt="">
              </a>
            </div>
            <div class="header__nav-mob-block d-flex align-items-center">
              <a href="https://xonsaroy.uz/uz" target="_blank"><img src="@/assets/images/web_logo.svg"
                                                                    alt=""></a>
              <a href="https://t.me/XonSaroy" target="_blank"><img src="@/assets/images/telegram_logo.svg"
                                                                   alt=""></a>
              <a href="https://www.instagram.com/xonsaroyuz/" target="_blank"><img
                src="@/assets/images/instagram_logo.svg" alt=""></a>
            </div>
          </div>

        </div>
      </div>

    </header>
    <div class="second">

      <div
        class="top__participants_card all__participants_card second__page-card col-12 col-md-6"
      >
        <img :src="participant.path" alt="">

        <!-- Button trigger modal -->
        <button @click="likeImage(participant)" type="button" class="btn btn-primary like__onhover"
                data-bs-toggle="modal"
                data-bs-target="#loginModalId">
          <img src="@/assets/images/liked.svg" alt="">
        </button>

        <div class="top__participants_card_info all__participants_card_info">
          <div class="d-flex align-center justify-content-between mt-2">
            <div class="d-flex align-items-center">
              <p>{{ `${participant['fullname']}, ${participant.region}, ${participant.age} yosh` }}</p>
            </div>
            <div>
              <button>
                <img v-if="participant.liked" src="@/assets/images/liked.svg" alt="">
                <img v-else src="@/assets/images/not_liked.svg" alt="">
                <span>{{ participant['likes'] }}</span>
              </button>
            </div>
          </div>
        </div>

        <div
          class="search__result-block col-12 col-lg-12 mt-3 mt-sm-4"
        >
          <input
            :value="participant['copy_link']"
            readonly
            class="search__result-input cliptext"
            ref="copy"
            type="text"
          >
          <button
            @click="copyToClipboard(participant.copy_link)"
          >
            <img src="@/assets/images/copy-icon.png" alt="">
          </button>
        </div>
      </div>

      <loading
        loader="dots"
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        :height="100"
        :width="100"
        color="#1f568c"
      />

      <b-modal
        id="loginModalId"
        ref="loginModalRef"
        centered
        hide-footer
        hide-header
      >
        <div>
          <h5>Ro’yxatdan o’tish</h5>
          <div class="mb-3">
            <label for="name" class="form-label">Ismingizni kiriting
              <span>*</span>
            </label>
            <input v-model="form.name" type="text" class="form-control" id="name">
          </div>
          <div class="mb-3">
            <label for="phoneNumber" class="form-label">Telefon raqamingizni kiriting
              <span>*</span>
            </label>
            <input v-model="form.phone" type="number" class="form-control" id="phoneNumber">
          </div>

          <button @click="login" class="btn btn-primary">Kod jo'natish</button>
        </div>
      </b-modal>

      <b-modal
        id="verifyModalId"
        ref="verifyModalRef"
        centered
        hide-footer
        hide-header
      >
        <div>
          <h5>Tasdiqlash kodi</h5>
          <div class="mb-3">
            <label for="phoneNumber" class="form-label">Tasdiqlash kodini kiriting
              <span>*</span>
            </label>
            <input v-model="verifyCode" type="number" class="form-control" id="phoneNumber">
          </div>

          <button @click="verify" class="btn btn-primary">Tasdiqlash</button>
        </div>
      </b-modal>

      <b-modal
        id="likeModal"
        ref="likeModal"
        centered
        hide-footer
        hide-header
        class="like-modal"
      >
        <div>
          <div class="mb-3">
            <h6 class="modal-content-text">Sizning "Like" ingiz hisobga olindi!</h6>
          </div>

          <button @click="$refs.likeModal.hide()" class="btn btn-primary">Chiqish</button>
        </div>
      </b-modal>

      <b-modal
        id="dislikeModal"
        ref="dislikeModal"
        centered
        hide-footer
        hide-header
        class="dislike-modal"
      >
        <div>
          <div class="mb-3">
            <h6 class="modal-content-text">Sizning "Like" ingiz olib tashlandi!</h6>
          </div>

          <button @click="$refs.dislikeModal.hide()" class="btn btn-primary">Chiqish</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from 'vue-loading-overlay';

export default {
  name: "SecondPage",
  components: {
    Loading
  },
  data() {
    return {
      id: null,
      isLoading: false,
      fullPage: true,
      disableBtn: false,
      loggedIn: null,
      form: {
        name: '',
        phone: null,
      },
      verifyCode: null,
      participant: null,
      windowWidth: null,
    }
  },
  async mounted() {
    this.windowWidth = window.innerWidth
    this.isLoading = true
    this.id = this.$route.params.id
    await this.getOneParticipant()
    await this.checkToken()
    this.isLoading = false
  },

  methods: {
    async getOneParticipant() {
      await axios.get(`https://api.kids.xonsaroy.uz/api/show/${this.id}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          this.participant = res.data.result
        })
        .catch(err => {
          console.error(err)
        })
    },

    likeImage(item) {
      console.log(item.liked, 'outside')
      if (this.loggedIn) {
        console.log(item.liked, '1 inside')
        if (item.liked === false) {
          console.log(item.liked, '2 inside')
          axios.get(`https://api.kids.xonsaroy.uz/api/like/${item.id}`, {
            headers: {
              Authorization: localStorage.getItem('token')
            }
          })
            .then(() => {
              this.$refs.likeModal.show()
            })
            .catch(err => {
              console.error(err)
            })
        } else {
          axios.get(`https://api.kids.xonsaroy.uz/api/dislike/${item.id}`, {
            headers: {
              Authorization: localStorage.getItem('token')
            }
          })
            .then(() => {
              this.$refs.dislikeModal.show()
            })
            .catch(err => {
              console.error(err)
            })
        }
      } else {
        this.$refs.loginModalRef.show()
      }
    },

    login() {
      const body = this.form
      axios.post('https://api.kids.xonsaroy.uz/api/auth/login', body)
        .then(() => {
          this.$refs.verifyModalRef.show()
        })
        .catch(err => {
          console.error(err)
        })
    },

    verify() {
      const body = {
        phone: this.form.phone,
        verify_code: parseInt(this.verifyCode)
      }
      axios.post('https://api.kids.xonsaroy.uz/api/auth/verify', body)
        .then(res => {
          localStorage.setItem('token', `Bearer ${res.data['access_token']}`)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.checkToken()
          this.verifyCode = null
          this.$refs.verifyModalRef.hide()
          this.$refs.loginModalRef.hide()
          this.form.phone = null
          this.form.name = null
        })
    },

    checkToken() {
      if (localStorage.getItem("token")) {
        this.loggedIn = true
      } else {
        this.loggedIn = false
      }
    },

    copyToClipboard(textToCopy) {
      const clipBoard = navigator.clipboard;
      clipBoard.writeText(textToCopy).then(() => {
      });
    }
  }
}
</script>

<style lang="scss" src="../assets/main.scss">

</style>
