<template>
  <div>
    <TheHeader/>

    <section class="top__participants">
      <div class="container">
        <h2>top-3 ishtirokchilar</h2>

        <div class="top__participants_cards">

          <div
            v-for="item in topParticipants"
            :key="item.id"
            class="top__participants_card col-4"
          >
            <img :src="item.path" alt="">

            <!-- Button trigger modal -->
            <button @click="likeTopImage(item)" class="btn btn-primary like__onhover">
              <img src="@/assets/images/liked.svg" alt="">
            </button>

            <div class="top__participants_card_info d-flex align-items-center justify-content-around mt-2">
              <p class="col-8">{{ `${item['fullname']}, ${item.region}, ${item.age} yosh` }}</p>
              <div class="d-flex align-center">
                <button>
                  <img v-if="item.liked" src="@/assets/images/liked.svg" alt="">
                  <img v-else src="@/assets/images/not_liked.svg" alt="">
                </button>
                <span>{{ item['likes'] }}</span>
              </div>
            </div>
          </div>

        </div>

        <div class="top__participants_cards-mob">
          <swiper
            :options="swiperOptions"
            ref="swiperRef"
          >
            <swiper-slide
              v-for="item in topParticipants"
              :key="item.id"
            >
              <div class="top__participants_card">
                <img :src="item.path" alt="">

                <!-- Button trigger modal -->
                <button @click="likeTopImage(item)" type="button" class="btn btn-primary like__onhover">
                  <img src="@/assets/images/liked.svg" alt="">
                </button>

                <div
                  class="top__participants_card_info d-flex align-items-center justify-content-around">
                  <p class="col-8">{{ `${item['fullname']}, ${item.region}, ${item.age} yosh` }}</p>
                  <div class="d-flex align-center">
                    <button>
                      <img v-if="item.liked" src="@/assets/images/liked.svg" alt="">
                      <img v-else src="@/assets/images/not_liked.svg" alt="">
                    </button>
                    <span>{{ item['likes'] }}</span>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

      </div>
    </section>

    <section class="all__participants">
      <div class="container">
        <h2>BARCHA ISHTIROKCHILAR: {{ participantsCount }}</h2>

        <div class="search__block mb-5">
          <span>Ishtirokchini izlash</span>
          <div class="search__block-flex d-flex justify-content-between">
            <input type="text" v-model="searchName">
            <button class="search__block_btn" @click="searchParticipants(searchName)">Izlash</button>
          </div>
        </div>

        <span v-if="searched && searchName !== ''" class="search__result my-4">"{{
            searchName
          }}" bo’yicha qidiruv</span>

        <div class="all__participants_cards d-flex justify-content-center flex-wrap">

          <div
            v-for="participant in participants"
            :key="participant.id"
            class="top__participants_card all__participants_card col-12 col-md-6"
            :class="!searched ? 'margin__minus' : ''"
          >
            <img :src="participant.path" alt="">

            <!-- Button trigger modal -->
            <button @click="likeImage(participant)" type="button" class="btn btn-primary like__onhover"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModalId">
              <img src="@/assets/images/liked.svg" alt="">
              <!--                     <img v-else src="@/assets/images/not_liked2.svg" alt="">-->
            </button>

            <div class="top__participants_card_info all__participants_card_info">
              <div class="d-flex align-center justify-content-between mt-2">
                <div class="d-flex align-items-center">
                  <p>{{ `${participant['fullname']}, ${participant.region}, ${participant.age} yosh` }}</p>
                </div>
                <div>
                  <button>
                    <img v-if="participant.liked" src="@/assets/images/liked.svg" alt="">
                    <img v-else src="@/assets/images/not_liked.svg" alt="">
                    <span>{{ participant['likes'] }}</span>
                  </button>
                </div>
              </div>
            </div>

            <div
              v-if="searched"
              class="search__result-block col-12 col-lg-12 mt-3 mt-sm-4"
            >
              <input
                :value="participant['copy_link']"
                readonly
                class="search__result-input cliptext"
                ref="copy"
                type="text"
              >
              <button
                @click="copyToClipboard(participant.copy_link)"
              >
                <img src="@/assets/images/copy-icon.png" alt="">
              </button>
            </div>
          </div>
        </div>

        <button
          v-if="!disableBtn"
          class="all__participants_dropdown"
          :class="searched ? 'more__space' : ''"
          :disabled="disableBtn"
          @click="viewMore"
        >
          <img src="@/assets/images/arrow_down.svg" alt="">
        </button>

      </div>
    </section>

    <footer class="footer">
      <div class="container">
        <div class="footer__block d-flex align-items-center justify-content-between">
          <div class="footer__block_logo">
            <img src="@/assets/images/footer_logo.svg" alt="">
          </div>
          <div class="footer__block_info">
            <div class="d-flex align-items-center justify-content-end mb-3">
              <a href="https://xonsaroy.uz/uz" target="_blank">
                <img src="@/assets/images/web_logo.svg" alt="">
              </a>
              <a href="https://t.me/XonSaroy" target="_blank">
                <img src="@/assets/images/telegram_logo.svg" alt="">
              </a>
              <a href="https://www.instagram.com/xonsaroyuz/" target="_blank">
                <img src="@/assets/images/instagram_logo.svg" alt="">
              </a>
            </div>
            <a class="footer__block_tel" href="tel:998555017400">55 501 74 00</a>
            <p>Toshkent shahri, Yunusobod tumani, <br>
              Yangi Shahar ko’chasi, 64А uy</p>
          </div>
        </div>
      </div>

      <!--    <div class="footer__block-mob">-->
      <!--        <img class="footer__block_bg-mob" src="../img/footer_bg-mob.svg" alt="">-->
      <!--        <div class="container d-flex flex-column align-items-end">-->
      <!--            <img src="../img/header_logo.svg" alt="">-->
      <!--            <a href="tel:9988555017400">55 501 74 00</a>-->
      <!--            <p class="text-end">г. Ташкент, Юнусабадский район, <br>-->
      <!--                улица Янги шахар, дом 64А</p>-->
      <!--            <div class="footer__links-mob">-->
      <!--                <a href="#">-->
      <!--                    <img src="../img/web_logo.svg" alt="">-->
      <!--                </a>-->
      <!--                <a href="#">-->
      <!--                    <img src="../img/telegram_logo.svg" alt="">-->
      <!--                </a>-->
      <!--                <a href="#">-->
      <!--                    <img src="../img/instagram_logo.svg" alt="">-->
      <!--                </a>-->
      <!--            </div>-->
      <!--        </div>-->
      <!--    </div>-->
    </footer>

    <loading
      loader="dots"
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      :height="100"
      :width="100"
      color="#1f568c"
    />

    <b-modal
      id="loginModalId"
      ref="loginModalRef"
      centered
      hide-footer
      hide-header
    >
      <div>
        <h5>Ro’yxatdan o’tish</h5>
        <div class="mb-3">
          <label for="name" class="form-label">Ismingizni kiriting
            <span>*</span>
          </label>
          <input v-model="form.name" type="text" class="form-control" id="name">
        </div>
        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Telefon raqamingizni kiriting
            <span>*</span>
          </label>
          <input v-model="form.phone" type="number" class="form-control" id="phoneNumber">
        </div>

        <button @click="login" class="btn btn-primary">Kod jo'natish</button>
      </div>
    </b-modal>

    <b-modal
      id="verifyModalId"
      ref="verifyModalRef"
      centered
      hide-footer
      hide-header
    >
      <div>
        <h5>Tasdiqlash kodi</h5>
        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Tasdiqlash kodini kiriting
            <span>*</span>
          </label>
          <input v-model="verifyCode" type="number" class="form-control" id="phoneNumber">
        </div>

        <button @click="verify" class="btn btn-primary">Tasdiqlash</button>
      </div>
    </b-modal>

    <b-modal
      id="likeModal"
      ref="likeModal"
      centered
      hide-footer
      hide-header
      class="like-modal"
    >
      <div>
        <div class="mb-3">
          <h6 class="modal-content-text">Sizning "Like" ingiz hisobga olindi!</h6>
        </div>

        <button @click="closeLikeModal" class="btn btn-primary">Chiqish</button>
      </div>
    </b-modal>

    <b-modal
      id="dislikeModal"
      ref="dislikeModal"
      centered
      hide-footer
      hide-header
      class="dislike-modal"
    >
      <div>
        <div class="mb-3">
          <h6 class="modal-content-text">Sizning "Like" ingiz olib tashlandi!</h6>
        </div>

        <button @click="closeDislikeModal" class="btn btn-primary">Chiqish</button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import axios from "axios";
import TheHeader from "@/components/TheHeader.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "HomePage",
  components: {
    TheHeader,
    Swiper,
    SwiperSlide,
    Loading
  },
  data() {
    return {
      swiperOptions: {
        // slidesPerView: "auto",
        spaceBetween: 30,
        centeredSlides: true,
        // loop: 'infinity',
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // on: {
        //   init: this.removeDuplicateSlides,
        // },
      },
      topParticipants: [],
      participants: [],
      newParticipants: [],
      participantsCount: null,
      searched: false,
      searchName: '',
      pagination: {
        current: 1,
        perPage: 8
      },
      isLoading: false,
      fullPage: true,
      disableBtn: false,
      loggedIn: null,
      form: {
        name: '',
        phone: null,
      },
      verifyCode: null,
      waitingItem: {},
      topParticipantsTrigger: false
    }
  },

  async mounted() {
    this.isLoading = true
    await this.checkToken()
    await this.getTopParticipants()
    await this.getAllParticipants()
    this.isLoading = false
  },

  methods: {
    async getTopParticipants() {
      await axios.get('https://api.kids.xonsaroy.uz/api/topImages', {
        headers: {
          Authorization: localStorage.getItem('token')
        },
      })
        .then(res => {
          this.topParticipants = res.data.result
        })
        .catch(err => {
          console.error(err)
        })
    },

    async getAllParticipants(currentPage, limit) {
      const params = {
        page: currentPage || this.pagination.current,
        limit: limit || this.pagination.perPage
      }
      await axios.get('https://api.kids.xonsaroy.uz/api/images', {
        headers: {
          Authorization: localStorage.getItem('token')
        },
        params: params,
      })
        .then(res => {
          this.newParticipants = res.data.result
          if (!this.participants.length) {
            this.participants = this.participants.concat(this.newParticipants)
          } else {
            // let ids = new Set(this.participants.map(item => item.id))
            // this.participants = [...this.participants, ...this.newParticipants.filter(newItem => !ids.has(newItem.id))]
            this.newParticipants.forEach(newItem => {
              let existingIndex = this.participants.findIndex(item => item.id === newItem.id);

              if (existingIndex !== -1) {
                if (this.participants[existingIndex].liked !== newItem.liked) {
                  this.$set(this.participants, existingIndex, {
                    ...this.participants[existingIndex],
                    liked: newItem.liked
                  });
                }
              } else {
                this.$set(this.participants, this.participants.length, newItem);
              }
            });
          }
          this.participantsCount = this.participants.length
          if (this.newParticipants.length < 8) {
            this.disableBtn = true
          }
        })
        .catch(err => {
          console.error(err)
        })
    },

    async viewMore() {
      this.isLoading = true
      await this.getAllParticipants(this.pagination.current += 1)
      this.isLoading = false
    },

    searchParticipants(params) {
      axios.get('https://api.kids.xonsaroy.uz/api/search', {params: {fullname: params}})
        .then(res => {
          this.participants = res.data.result
          this.searched = true
          this.isLoading = true
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async likeTopImage(item) {
      this.topParticipantsTrigger = true;
      this.waitingItem = item;
      await this.checkToken()
      if (this.loggedIn) {
        this.topParticipants.forEach((participant) => {
          if (participant.id === item.id) {
            if (participant.liked === false) {
              axios.get(`https://api.kids.xonsaroy.uz/api/like/${item.id}`, {
                headers: {
                  Authorization: localStorage.getItem('token')
                }
              })
                .then(() => {
                  this.$refs.likeModal.show()
                })
                .catch(err => {
                  console.error(err)
                  if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    this.loggedIn = false;
                    this.$refs.loginModalRef.show()
                  }
                })
                .finally(async () => {
                  await this.getTopParticipants()
                })
            } else {
              axios.get(`https://api.kids.xonsaroy.uz/api/dislike/${item.id}`, {
                headers: {
                  Authorization: localStorage.getItem('token')
                }
              })
                .then(() => {
                  this.$refs.dislikeModal.show()
                })
                .catch(err => {
                  console.error(err)
                  if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    this.loggedIn = false;
                    this.$refs.loginModalRef.show()
                  }
                })
                .finally(async () => {
                  await this.getTopParticipants()
                })
            }
          }
        });
      } else {
        this.$refs.loginModalRef.show()
      }
    },

    async likeImage(item) {
      this.topParticipantsTrigger = false;
      this.waitingItem = item;
      await this.checkToken()
      if (this.loggedIn) {
        this.participants.forEach((participant) => {
          if (participant.id === item.id) {
            if (participant.liked === false) {
              axios.get(`https://api.kids.xonsaroy.uz/api/like/${item.id}`, {
                headers: {
                  Authorization: localStorage.getItem('token')
                }
              })
                .then(() => {
                  this.$refs.likeModal.show()
                })
                .catch(err => {
                  console.error(err)
                  if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    this.loggedIn = false;
                    this.$refs.loginModalRef.show()
                  }
                })
                .finally(async () => {
                  await this.getTopParticipants()
                })
            } else {
              axios.get(`https://api.kids.xonsaroy.uz/api/dislike/${item.id}`, {
                headers: {
                  Authorization: localStorage.getItem('token')
                }
              })
                .then(() => {
                  this.$refs.dislikeModal.show()
                })
                .catch(err => {
                  console.error(err)
                  if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    this.loggedIn = false;
                    this.$refs.loginModalRef.show()
                  }
                })
                .finally(async () => {
                  await this.getTopParticipants()
                })
            }
          }
        })
      } else {
        this.$refs.loginModalRef.show()
      }
    },

    login() {
      const body = this.form
      axios.post('https://api.kids.xonsaroy.uz/api/auth/login', body)
        .then(() => {
          this.$refs.verifyModalRef.show()
        })
        .catch(err => {
          console.error(err)
        })
    },

    verify() {
      const body = {
        phone: this.form.phone,
        verify_code: parseInt(this.verifyCode)
      }
      axios.post('https://api.kids.xonsaroy.uz/api/auth/verify', body)
        .then(async (res) => {
          localStorage.setItem('token', `Bearer ${res.data['access_token']}`)
          await this.getTopParticipants()
          await this.getAllParticipants(1,this.pagination.current * this.pagination.perPage)
          setTimeout(async () => {
            if (this.topParticipantsTrigger) {
              await this.likeTopImage(this.waitingItem)
            } else {
              await this.likeImage(this.waitingItem)
            }
          }, 500)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.checkToken()
          this.verifyCode = null
          this.$refs.verifyModalRef.hide()
          this.$refs.loginModalRef.hide()
          this.form.phone = null
          this.form.name = null
        })
    },

    checkToken() {
      this.loggedIn = !!localStorage.getItem("token");
    },

    copyToClipboard(textToCopy) {
      const clipBoard = navigator.clipboard;
      clipBoard.writeText(textToCopy).then(() => {
      });
    },

    async closeDislikeModal() {
      this.participants.forEach((participant) => {
        if (participant.id === this.waitingItem.id) {
          participant.likes -= 1
          participant.liked = !participant.liked
        }
      })
      this.$refs.dislikeModal.hide()
    },

    async closeLikeModal() {
      this.participants.forEach((participant) => {
        if (participant.id === this.waitingItem.id) {
          participant.likes += 1
          participant.liked = !participant.liked
        }
      })
      this.$refs.likeModal.hide()
    },

    // removeDuplicateSlides() {
    //   const swiperInstance = this.$refs.swiperRef.swiper;
    //   const duplicateSlides = swiperInstance.slides.filter(slide => slide.classList.contains('swiper-slide-duplicate'));
    //
    //   duplicateSlides.forEach(duplicateSlide => {
    //     duplicateSlide.style.display = 'none';
    //   });
    //
    //   // Update Swiper to recalculate slides
    //   swiperInstance.update();
    // },
  }
}
</script>

<style lang="scss" src="../assets/main.scss"></style>
