import Vue from 'vue'
import VueRouter from 'vue-router'
import SecondPage from "@/views/SecondPage.vue";
import HomePage from "@/views/HomePage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: `/show/:id`,
    name: "second",
    component: SecondPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
