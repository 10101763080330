<template>
   <div class="app-main">
<!--      <TheHeader/>-->
      <router-view/>
   </div>
</template>

<script>
// import TheHeader from "@/components/TheHeader.vue";

export default {
   name: 'App',
   // components: {TheHeader},
}
</script>

<style lang="scss" src="./assets/main.scss"></style>
