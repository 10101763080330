<template>
   <header class="header">
      <img class="header__bg_image" src="@/assets/images/xonsaroy-kids-desktop.png" alt="" style="height: 100vh; top:0">
      <img class="header__bg_image-mob" src="@/assets/images/xonsaroy-kids-mobile.png" alt=""
           style="height: 100vh; top:0">
      <div class="container">
         <div class="p-relative">
            <div v-if="windowWidth > 576" class="header__nav">
               <div class="container header__nav_block d-flex align-items-center justify-content-between">
                  <div class="header__logo">
                     <router-link :to="{name: 'home'}">
                        <img src="@/assets/images/header_logo.svg" alt="logo">
                     </router-link>
                  </div>
                  <div class="header__links">
                     <a href="tel:998555017400">55 501 74 00</a>
                     <a href="https://xonsaroy.uz/uz" target="_blank"><img src="@/assets/images/web_logo.svg" alt=""></a>
                     <a href="https://t.me/XonSaroy" target="_blank"><img src="@/assets/images/telegram_logo.svg" alt=""></a>
                     <a href="https://www.instagram.com/xonsaroyuz/" target="_blank"><img src="@/assets/images/instagram_logo.svg" alt=""></a>
                  </div>
               </div>
            </div>
            <div v-else class="header__nav-mob container d-flex align-items-center justify-content-between">
               <div class="col-6 col-sm-4">
                  <a href="#">
                     <img src="@/assets/images/header_logo.svg" alt="">
                  </a>
               </div>
               <div class="header__nav-mob-block d-flex align-items-center">
                  <a href="https://xonsaroy.uz/uz" target="_blank"><img src="@/assets/images/web_logo.svg" alt=""></a>
                  <a href="https://t.me/XonSaroy" target="_blank"><img src="@/assets/images/telegram_logo.svg" alt=""></a>
                  <a href="https://www.instagram.com/xonsaroyuz/" target="_blank"><img src="@/assets/images/instagram_logo.svg" alt=""></a>
               </div>
            </div>
            <!--            <div class="container">-->
            <!--               <div class="p-relative">-->
            <!--                  <div class="header__cloud">-->
            <!--                     <img src="@/assets/images/Vector.svg" alt="">-->
            <!--                     <img src="@/assets/images/text.svg" alt="">-->
            <!--                  </div>-->

            <!--               </div>-->
            <!--            </div>-->

         </div>
      </div>

      <img class="mouse-animation" src="@/assets/images/arrow-animation.svg" alt="">

      <!--      <div class="header__search">-->
      <!--         <span>Ishtirokchilarni izlash</span>-->
      <!--         <div class="d-flex align-items-center justify-content-between">-->
      <!--            <input type="text" v-model="searchName">-->
      <!--            <button @click="searchParticipants(searchName)">Izlash</button>-->
      <!--         </div>-->
      <!--      </div>-->
   </header>
</template>

<script>
import axios from "axios";

export default {
   name: "TheHeader",
   data() {
      return {
         windowWidth: null,
      }
   },

   mounted() {
      this.windowWidth = window.innerWidth
   },
}
</script>

<style lang="scss" src="../assets/main.scss"></style>
